<template>
	<div>
		
		<div class="Top">
			<span class="On">店铺设置</span>
		</div>
		
		<div class="ShopInfo">
			

		
			
			<div class="Item">
				<span>店铺名</span>
				<div>
					{{Shop.Name}}
				</div>
			</div>
			
			<div class="Item">
				<span>店招</span>
				<div>
					<el-upload
					  class="avatar-uploader"
					  action="https://jsonplaceholder.typicode.com/posts/"
					  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
					<img v-if="Shop.Logo && Shop.Logo != ''" :src="Shop.Logo" style="width: 150px;border-radius: 75px;" />
					
					<div v-else style="width: 150px;height: 150px;background-color: rgba(0,0,0,0.1);border-radius: 75px;display: flex;align-items: center;justify-content: center;">
						<el-button circle type="primary" icon="el-icon-plus" size="mini"></el-button>
					</div>
					</el-upload>
				</div>
			</div>
			
			<div class="Item">
				<span>店铺简介</span>
				<div>
					<el-input style="width: 400px;" type="textarea" v-model="Shop.Description"></el-input>
				</div>
			</div>
			
			<div class="Item">
				<span>店铺状态</span>
				<div>
					<el-select v-model="Shop.Status">
						<el-option :label="'营业中'" :value="50"></el-option>
						<el-option :label="'暂停营业'" :value="10"></el-option>
					</el-select>
				</div>
			</div>
			
			<div class="Item">
				<span>开店时间</span>
				<div>
					{{Shop.CreatedAt}}
				</div>
			</div>
			
			
			<div class="Item">
				<span></span>
				<div>
					<el-button type="danger" @click="UpdateShop()">提交修改</el-button>
				</div>
			</div>
			
		</div>
		
		
	</div>	
</template>

<script>
	import {Select,Option,Upload} from 'element-ui'
	export default {
	  name: 'ShopSetting',
	  props: {
	  },
	  data() {
	      return {
			  Shop:{
				  Id:'',
				  Name:'',
				  Status:50,
				  StatusName:'营业中',
				  CreatedAt:'',
				  Logo:'',
				  Description:''
			  }
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-upload':Upload
	  },
	  created() {
		  this.Shop.Id = this.$store.getters.getShopId
	  	this.GetShop()
	  },
	  methods:{
		  GetShop(){

		  		let data = {
		  			Service:'Shop',
		  			Class: 'Shop',
		  			Action: 'Get',
		  			Id:this.$store.getters.getShopId
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.Shop = res.Data
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  getFile(file, fileList) {
		  				this.getBase64(file.raw).then(res => {
		  					this.uploadPic(res)
		  			    });
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
		  uploadPic(_b64){
			  let data = {
				Service:'File',
				Class: 'File',
				Action: 'Base64Upload',
				Base64:_b64
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				this.$message(res.Msg)
				if(res.ErrorId != 0){
					return
				}
				
				this.Shop.Logo = res.Data.Url
				
				
			  })
		  },
		  UpdateShop(){
		  
		  		let data = {
		  			Service:'Shop',
		  			Class: 'Shop',
		  			Action: 'Update',
		  			Id:this.$store.getters.getShopId,
					Status:this.Shop.Status,
					Logo:this.Shop.Logo,
					Description:this.Shop.Description
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			this.$message(res.Msg)
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
	.Top{
		margin: 20px;
		padding: 0px 20px 0px 0px;
		background-color: #FFFFFF;
		display: block;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid rgba(255,94,97,1);
	}
	.Top span{
		margin-right: 10px;
		padding: 0px 10px;
		line-height: 40px;
		display: inline-block;
		border-radius: 5px 5px 0px 0px;
		cursor: pointer;
	}
	.Top span.On,.Top span:hover{
		background-color: rgba(255,94,97,1);
		color: #FFFFFF;
	}
	.ShopInfo{
		padding: 30px;
		background-color: #FFFFFF;
		margin: 20px;
	}
	.ShopInfo .Item{
		display: flex;
		margin-bottom: 20px;
		align-items: center;
	}
	.ShopInfo .Item span{
		display: inline-block;
		width: 120px;
		padding-right: 10px;
		text-align: right;
		color: rgba(0,0,0,0.4);
		
	}
	.ShopInfo .Item span:after{
		content: ":";
	}
</style>
